  /**
   * Return the Twine username corresponding to the current hostname.
   *
   * If the hostname is not in the list of known hostnames, returns the first
   * subdomain as the username.
   *
   * @returns {string} the Twine username associated with the current hostname.
   */
export const getUsernameFromURL = () => {
  const hostname = window.location.hostname;
  let username;

  switch (hostname) {
    case "localhost":
    case "stuartlogan.com":
      username = "stuart";
      break;
    case "daptrax.com":
      username = "compserdap";
      break;
    case "ikaradesign.eu":
      username = "oanacarianopol5582";
      break;
    case "313mediagroup.com":
      username = "khuber8076";
      break;
    case "ericoh.ca":
      username = "echo416";
      break;
    case "galacticeclipse.com":
      username = "Phenomenone";
      break;
    case "thechaco.com":
      username = "Alejandro1366";
      break;
    case "www.kerryleatham.com":
      username = "KLeatham";
      break;
    case "cloudfoundrymusic.com":
      username = "james4921";
      break;
    case "mimosamusic.com.au":
      username = "jamesbdarcy";
      break;
    case "zibaconcept.com":
      username = "kourossissan";
      break;
    case "rsdm.com.au":
      username = "RSDM";
      break;
    case "ivelissedelcarmen.com":
        username = "ive00uk";
        break;
    case "yadirastampfli.com":
      username = "YadiraS";
      break;
    case "rossthomascreations.com":
      username = "rossthomas1";
      break;
    case "www.oztar.com":
      username = "oztarc7878";
      break;
    case "gemafawell.dev":
      username = "GemAfaWell1";
      break;
    case "hugo-sevilla-gomez-llanos.dev":
      username = "HugoSevillaGomezLl";
      break;
    case "yuripardi.com":
      username = "yuripardi4585";
      break;
    case "dandelboy.com":
      username = "DanDelboy";
      break;
    case "irekdreger.com":
      username = "Irek";
      break;
    case "nicerecords.au":
      username = "nicerecords";
      break;
    case "www.andreapossee.com":
      username = "apossee4242";
      break;
    case "lawrencetimonimusic.com":
      username = "Lawrence81";
      break;
    case "bitlabs.com.pe":
      username = "GabrielPardo";
      break;
    case "catrosinskyscreenproduction.com.au":
      username = "CatRosinsky";
      break;
    case "guillermoronda.com":
      username = "guillermorondaaran33";
      break;
    case "www.allegra-sun.com":
      username = "Allegrasun";
      break;
    case "carlmerenick.ca":
      username = "cmerenick";
      break;
    case "twine.tobynutter.co.uk":
      username = "TobyNutter";
      break;
    case "anwardyfan.site":
      username = "AnwarDyfan";
      break;
    case "www.yahyiaproductions.co.uk":
      username = "yahyia99";
      break;
    case "lookbeyond.sg":
      username = "juice100";
      break;
    case "lucianatorre.com":
      username = "lucianatorre";
      break;
    case "dinamictechnologies.com":
      username = "lucianatorre";
      break;
    case "metalicart.au":
      username = "Metalicart";
      break;
    case "www.erickjaredwall.com":
      username = "EJW2024";
      break;
    case "yonkabeats.site":
      username = "BiancaBell";
      break;
    case "www.feliperolli.com":
      username = "FelipeRolli";
      break;
    case "www.jcutedit.com":
      username = "JoeBoyle";
      break;
    case "www.lifeworkz.co.uk":
      username = "mark1522";
      break;
    case "www.thomassiemens.com":
      username = "thomassiemens";
      break;
    case "danielezanini.com":
      username = "DanieleZanini";
      break;
    case "camillealyssafilms.com":
      username = "camillealyssa";
      break;
    case "www.elifturkmen.net":
      username = "armonilla";
      break;
    case "dwightsvoices.com":
    case "www.dwightsvoices.com":
      username = "DwightsVoices";
      break;
    case "studioproof.com.au":
    case "www.studioproof.com.au":
      username = "info6400";
      break;
    case "andoneousdj.online":
    case "www.andoneousdj.online":
      username = "AndoneousDJ";
      break;
    case "muhammadhaiderpervaiz.com":
      username = "muhamadhaiderpervaiz";
      break;
    case "dsedit.org":
      username = "stubbsdrew8612";
      break;
    case "camtalestudios.com":
      username = "Rubie";
      break;
    case "vincentsung.com":
      username = "Visualsponge";
      break;
    case "carlafrazao.com":
      username = "crlfrz";
      break;
    case "evandeng.co":
      username = "evvdeng";
      break;
    case "cameronlarsh.com":
      username = "CameronLarsh";
      break;
    default:
      username = hostname.split(".")[0];
  }
  return username;
};

export function beginsWithVowel(string) {
  return /^[aeiouAEIOU]/.test(string);
}
